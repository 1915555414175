"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.minorRoutineUpdate = exports.majorRoutineUpdate = void 0;
const majorRoutineUpdate = (routine, workout) => {
    var _a, _b;
    const exercises = workout.exercises.map((e) => {
        const occurrenceInWorkout = workout.exercises
            .filter((it) => it.exerciseTemplateId === e.exerciseTemplateId)
            .indexOf(e);
        const routineExercise = routine.exercises.filter((it) => it.exercise_template_id === e.exerciseTemplateId)[occurrenceInWorkout];
        if (!!routineExercise) {
            const exercise = {
                exercise_template_id: e.exerciseTemplateId,
                superset_id: e.supersetId,
                rest_seconds: e.autoRestTimerSeconds,
                sets: e.sets.map((set, setIndex) => {
                    var _a, _b;
                    const newSet = set;
                    const oldSet = routineExercise.sets[setIndex];
                    return {
                        index: setIndex,
                        indicator: newSet.indicator,
                        weight_kg: (newSet === null || newSet === void 0 ? void 0 : newSet.weight) || (oldSet === null || oldSet === void 0 ? void 0 : oldSet.weight_kg),
                        reps: routineExercise.input_modifier === 'rep-range'
                            ? undefined
                            : (newSet === null || newSet === void 0 ? void 0 : newSet.reps) || (oldSet === null || oldSet === void 0 ? void 0 : oldSet.reps),
                        distance_meters: (newSet === null || newSet === void 0 ? void 0 : newSet.distance) || (oldSet === null || oldSet === void 0 ? void 0 : oldSet.distance_meters),
                        duration_seconds: (newSet === null || newSet === void 0 ? void 0 : newSet.duration) || (oldSet === null || oldSet === void 0 ? void 0 : oldSet.duration_seconds),
                        custom_metric: (newSet === null || newSet === void 0 ? void 0 : newSet.customMetric) || (oldSet === null || oldSet === void 0 ? void 0 : oldSet.custom_metric),
                        rpe: oldSet === null || oldSet === void 0 ? void 0 : oldSet.rpe,
                        rep_range: routineExercise.input_modifier === 'rep-range'
                            ? (((_a = oldSet === null || oldSet === void 0 ? void 0 : oldSet.rep_range) === null || _a === void 0 ? void 0 : _a.start) || undefined) !== undefined ||
                                (((_b = oldSet === null || oldSet === void 0 ? void 0 : oldSet.rep_range) === null || _b === void 0 ? void 0 : _b.end) || undefined) !== undefined
                                ? oldSet === null || oldSet === void 0 ? void 0 : oldSet.rep_range
                                : { start: (newSet === null || newSet === void 0 ? void 0 : newSet.reps) || null, end: null }
                            : undefined,
                    };
                }),
                notes: routineExercise.notes,
                input_modifier: routineExercise.input_modifier,
            };
            return exercise;
        }
        else {
            const exercise = {
                exercise_template_id: e.exerciseTemplateId,
                superset_id: e.supersetId,
                rest_seconds: e.autoRestTimerSeconds,
                sets: e.sets.map((set, setIndex) => {
                    var _a;
                    const newSet = set;
                    return {
                        index: setIndex,
                        indicator: (_a = newSet === null || newSet === void 0 ? void 0 : newSet.indicator) !== null && _a !== void 0 ? _a : set.indicator,
                        weight_kg: newSet === null || newSet === void 0 ? void 0 : newSet.weight,
                        reps: newSet === null || newSet === void 0 ? void 0 : newSet.reps,
                        distance_meters: newSet === null || newSet === void 0 ? void 0 : newSet.distance,
                        duration_seconds: newSet === null || newSet === void 0 ? void 0 : newSet.duration,
                        custom_metric: newSet === null || newSet === void 0 ? void 0 : newSet.customMetric,
                        rpe: undefined,
                        rep_range: undefined,
                    };
                }),
                notes: '',
                input_modifier: undefined,
            };
            return exercise;
        }
    });
    return {
        title: routine.title,
        exercises,
        parent_routine_id: (_a = routine.parent_routine_id) !== null && _a !== void 0 ? _a : undefined,
        folder_id: routine.folder_id,
        index: (_b = routine.index) !== null && _b !== void 0 ? _b : 0,
        program_id: routine.program_id,
        notes: routine.notes,
        coach_force_rpe_enabled: routine.coach_force_rpe_enabled,
    };
};
exports.majorRoutineUpdate = majorRoutineUpdate;
const minorRoutineUpdate = (routine, workout) => {
    var _a, _b;
    const exercises = routine.exercises.map((e) => {
        const occurrenceInRoutine = routine.exercises
            .filter((it) => it.exercise_template_id === e.exercise_template_id)
            .indexOf(e);
        const workoutExercise = workout.exercises.filter((it) => it.exerciseTemplateId === e.exercise_template_id)[occurrenceInRoutine];
        if (!!workoutExercise) {
            const exercise = {
                exercise_template_id: workoutExercise.exerciseTemplateId,
                superset_id: workoutExercise.supersetId,
                rest_seconds: workoutExercise.autoRestTimerSeconds,
                sets: e.sets.map((set, setIndex) => {
                    var _a, _b;
                    const setOccurrenceInRoutineExercise = e.sets
                        .filter((it) => it.indicator === set.indicator ||
                        (it.indicator === 'normal' && set.indicator === 'failure') ||
                        (it.indicator === 'failure' && set.indicator === 'normal'))
                        .indexOf(set);
                    const workoutSet = workoutExercise.sets.filter((it) => it.indicator === set.indicator ||
                        (it.indicator === 'normal' && set.indicator === 'failure') ||
                        (it.indicator === 'failure' && set.indicator === 'normal'))[setOccurrenceInRoutineExercise];
                    return {
                        index: setIndex,
                        indicator: set.indicator,
                        weight_kg: (workoutSet === null || workoutSet === void 0 ? void 0 : workoutSet.weight) || set.weight_kg,
                        reps: e.input_modifier === 'rep-range'
                            ? undefined
                            : (workoutSet === null || workoutSet === void 0 ? void 0 : workoutSet.reps) || set.reps,
                        distance_meters: (workoutSet === null || workoutSet === void 0 ? void 0 : workoutSet.distance) || set.distance_meters,
                        duration_seconds: (workoutSet === null || workoutSet === void 0 ? void 0 : workoutSet.duration) || set.duration_seconds,
                        custom_metric: (workoutSet === null || workoutSet === void 0 ? void 0 : workoutSet.customMetric) || set.custom_metric,
                        rpe: set.rpe,
                        rep_range: e.input_modifier === 'rep-range'
                            ? (((_a = set.rep_range) === null || _a === void 0 ? void 0 : _a.start) || undefined) !== undefined ||
                                (((_b = set.rep_range) === null || _b === void 0 ? void 0 : _b.end) || undefined) !== undefined
                                ? set.rep_range
                                : { start: (workoutSet === null || workoutSet === void 0 ? void 0 : workoutSet.reps) || set.reps || null, end: null }
                            : undefined,
                    };
                }),
                notes: e.notes,
                input_modifier: e.input_modifier,
            };
            return exercise;
        }
        else {
            const exercise = {
                exercise_template_id: e.exercise_template_id,
                superset_id: e.superset_id,
                rest_seconds: e.rest_seconds,
                sets: e.sets.map((set, setIndex) => ({
                    index: setIndex,
                    indicator: set.indicator,
                    weight_kg: set.weight_kg,
                    reps: set.reps,
                    distance_meters: set.distance_meters,
                    duration_seconds: set.duration_seconds,
                    custom_metric: set.custom_metric,
                    rpe: set.rpe,
                    rep_range: set.rep_range,
                })),
                notes: e.notes,
                input_modifier: e.input_modifier,
            };
            return exercise;
        }
    });
    return {
        title: routine.title,
        exercises,
        parent_routine_id: (_a = routine.parent_routine_id) !== null && _a !== void 0 ? _a : undefined,
        folder_id: routine.folder_id,
        index: (_b = routine.index) !== null && _b !== void 0 ? _b : 0,
        program_id: routine.program_id,
        notes: routine.notes,
        coach_force_rpe_enabled: routine.coach_force_rpe_enabled,
    };
};
exports.minorRoutineUpdate = minorRoutineUpdate;
