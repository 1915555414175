import { captureException } from '@sentry/nextjs';
import { modal } from 'components/Modals/ModalManager';
import { makeAutoObservable } from 'mobx';
import router from 'next/router';
import toast from 'react-hot-toast';
import { localStorageStores } from 'state/localStorageStores';
import { memoryStores } from 'state/memoryStores';
import { sendEvent } from 'utils/analyticsEvents';

class PlanAggregatorClass {
  constructor() {
    makeAutoObservable(this);
  }

  get isTeamPastPlanLimits() {
    if (localStorageStores.plan.clientLimit < memoryStores.clients.nonSampleClientsCount) {
      return true;
    }
    return false;
  }

  get isTeamExceedingFreeClientLimit() {
    return localStorageStores.plan.freePlanClientLimit < memoryStores.clients.nonSampleClientsCount;
  }

  get numberOfInvitesLeft() {
    const { plan, invites } = localStorageStores;
    const { clients } = memoryStores;
    return plan.clientLimit - (clients.nonSampleClients.length + invites.invites.length);
  }

  get isExpiringPlanExceedsFreeClientLimit() {
    return localStorageStores.plan.isPlanExpiring && this.isTeamExceedingFreeClientLimit;
  }

  get isExpiredPlanExceedsFreeClientLimit() {
    return localStorageStores.plan.isPlanExpired && this.isTeamExceedingFreeClientLimit;
  }

  handleInvalidPlanIfNeeded = async () => {
    const { currentPlan } = localStorageStores.plan;
    if (!currentPlan) {
      return;
    }

    if (
      currentPlan.type === 'paid_paddle' &&
      currentPlan.status === 'past_due' &&
      !!currentPlan.paddle_update_payment_url
    ) {
      modal.openAlertModal({
        title: 'Your most recent payment failed ',
        body: "Please note that if you don't update your billing details in the next couple days, you will lose access to certain features, including your clients' data. To regain access, you will need to subscribe to a plan again.",
        confirmButtonTitle: 'Update Billing Info',
        confirmButtonStyle: 'primary',
        handleAlertConfirm: async () => {
          if (!!currentPlan.paddle_update_payment_url) {
            sendEvent('paymentFailedModal_updateBillingInfo_click');
            router.push(currentPlan.paddle_update_payment_url);
          }
        },
        cancelButtonTitle: 'Not Now',
        onCancelClick: () => {
          sendEvent('paymentFailedModal_notNow_click');
        },
      });
    } else if (currentPlan.type === 'paid_paddle' && currentPlan.status === 'paused') {
      router.push('/settings/plan');
      modal.openAlertModal({
        title: 'Your plan has expired',
        body: 'Please reactivate your plan to continue using Hevy Coach.',
        confirmButtonTitle: 'Reactivate',
        confirmButtonStyle: 'primary',
        handleAlertConfirm: async () => {
          sendEvent('planExpired_reactivateClicked');
        },
        cancelButtonTitle: 'Not Now',
        onCancelClick: () => {
          sendEvent('planExpiredModal_notNow_click');
        },
      });
    } else if (currentPlan.type === 'trial' && currentPlan.status === 'paused') {
      modal.openFreeTrialEndModal();
      if (!router.asPath.includes('settings/profile')) {
        router.push('/settings/profile');
      }
    } else if (currentPlan.type === 'none') {
      if (localStorageStores.plan.isEligibleToStartFreeTrial) {
        try {
          // Migrate this user to the trial on launch since we've deprecated the free plan
          await localStorageStores.plan.startTrial();
        } catch (error) {
          captureException(error);
          toast.error('Unable to transition to 30-day trial, please contact support');
        }
        modal.openTrialStartedModal();
      } else {
        // They don't have a valid plan, show un un-dismissible upgrade modal
        modal.openUpgradePlanModal({
          isDissmisable: false,
          source: 'noValidPlan',
          title: 'Your plan has expired',
        });
      }
    }
  };
}

export const PlanAggregator = new PlanAggregatorClass();
