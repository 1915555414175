import {
  customExericseTypes,
  Equipment,
  equipments,
  ExerciseType,
  isEquipment,
  isExerciseType,
  isMuscleGroup,
  MuscleGroup,
  muscleGroups,
  validateYoutubeUrl,
} from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import { v4 as uuid } from 'uuid';
import { localStorageStores } from 'state/localStorageStores';
import {
  customExerciseTypeToHuman,
  equipmentToHuman,
  exerciseTypeToHuman,
  muscleGroupToHuman,
} from 'utils/exerciseUtils';
import { getUrlFromFile, resizeImage } from 'utils/imageUtils';
import { uploadImage, uploadVideo } from 'utils/uploadMedia';
import toast from 'react-hot-toast';
import API from 'utils/API';
import { fireAndForget } from 'utils/async';
import { sendEvent } from 'utils/analyticsEvents';
import { VideoAttachment } from 'state/localStorageStores/exerciseTemplateCustomizations';
import { VideoUploadCellViewModel } from './components/VideoUploadCell/VideoUploadCellViewModel';
import { modal } from '../ModalManager';

export class EditExerciseViewModel {
  editingBundledExerciseTemplateId?: string;
  editingCustomExerciseTemplateId?: string;
  imageUrlToCrop?: string;
  isUploadingImage: boolean = false;
  isSaving: boolean = false;
  remoteThumbnailUrl?: string;
  remoteImageUrl?: string;
  showErrors = false;
  onExerciseSavedHandler?: (exerciseTemplateId: string) => void;
  private _hasUnsavedChanges = false;
  exerciseTitle: string = '';
  defaultExerciseInstructions?: string;

  videoCellVm: VideoUploadCellViewModel;

  constructor(props: {
    onExerciseSaved?: (exerciseId: string) => void;
    exerciseId?: string;
    exerciseTitle?: string;
    videoCellVm: VideoUploadCellViewModel;
  }) {
    makeAutoObservable(this);
    this.onExerciseSavedHandler = props.onExerciseSaved;
    this.exerciseTitle = props.exerciseTitle || '';
    this.videoCellVm = props.videoCellVm;
    this.videoCellVm.onAddVideoAttachment = this.onAddVideoAttachment;
    this.videoCellVm.onSelectedFile = this.onUploadVideo;

    if (props.exerciseId) {
      const exerciseTemplateToBeEdited = localStorageStores.exerciseTemplates.getExercise(
        props.exerciseId,
      );

      if (exerciseTemplateToBeEdited) {
        this.selectedEquipment = {
          value: exerciseTemplateToBeEdited.equipment_category,
          label: equipmentToHuman(exerciseTemplateToBeEdited.equipment_category),
        };

        this.selectedExerciseType = {
          value: exerciseTemplateToBeEdited.exercise_type,
          label: exerciseTypeToHuman(exerciseTemplateToBeEdited.exercise_type),
        };

        this.selectedPrimaryMuscleGroup = {
          value: exerciseTemplateToBeEdited.muscle_group,
          label: muscleGroupToHuman(exerciseTemplateToBeEdited.muscle_group),
        };

        this.selectedSecondaryMuscleGroups = exerciseTemplateToBeEdited.other_muscles.map(mg => {
          return { value: mg, label: muscleGroupToHuman(mg) };
        });

        this.remoteThumbnailUrl = exerciseTemplateToBeEdited.thumbnail_url;
        this.remoteImageUrl = exerciseTemplateToBeEdited.custom_exercise_image_url;
        this.exerciseTitle = exerciseTemplateToBeEdited.title;
        this.defaultExerciseInstructions = exerciseTemplateToBeEdited.instructions?.trim();
        this.instructions = this.defaultExerciseInstructions ?? '';

        if (exerciseTemplateToBeEdited.is_custom) {
          this.editingCustomExerciseTemplateId = props.exerciseId;
        } else {
          this.editingBundledExerciseTemplateId = props.exerciseId;
        }

        const exerciseCustomizations =
          localStorageStores.exerciseTemplateCustomizations.getExerciseCustomization(
            props.exerciseId,
          );

        if (exerciseCustomizations) {
          this.videoAttachment = exerciseCustomizations.video;
          if (exerciseCustomizations.instructions) {
            this.instructions = exerciseCustomizations.instructions;
          }
        }
      }
    }
  }

  get isSaveEnabled(): boolean {
    return (
      this._hasUnsavedChanges &&
      !this.isUploadingImage &&
      !this.isUploadingVideo &&
      !this.isSaving &&
      !this.videoCellVm.hasYouTubeError
    );
  }

  get isCustomExercise(): boolean {
    return !this.editingBundledExerciseTemplateId;
  }

  get mode(): 'create-custom-exercise' | 'edit-custom-exercise' | 'edit-bundled-exercise' {
    if (!!this.editingCustomExerciseTemplateId) {
      return 'edit-custom-exercise';
    } else if (!!this.editingBundledExerciseTemplateId) {
      return 'edit-bundled-exercise';
    } else {
      return 'create-custom-exercise';
    }
  }

  get isEditing(): boolean {
    return !!this.editingBundledExerciseTemplateId || !!this.editingCustomExerciseTemplateId;
  }

  get modalTitle(): string {
    switch (this.mode) {
      case 'edit-custom-exercise':
        return 'Edit Custom Exercise';
      case 'create-custom-exercise':
        return 'Create Custom Exercise';
      case 'edit-bundled-exercise':
        return 'Edit Exercise';
    }
  }

  get buttonTitle(): string {
    switch (this.mode) {
      case 'edit-custom-exercise':
        return 'Update Custom Exercise';
      case 'create-custom-exercise':
        return 'Create Custom Exercise';
      case 'edit-bundled-exercise':
        return 'Update Exercise';
    }
  }

  get invalidFields(): {
    exerciseName: boolean;
    exerciseType: boolean;
    equipment: boolean;
    primaryMuscleGroup: boolean;
  } {
    const invalidFields = {
      exerciseName: false,
      exerciseType: false,
      equipment: false,
      primaryMuscleGroup: false,
    };
    if (!this.exerciseTitle) {
      invalidFields.exerciseName = true;
    }

    if (!this.selectedExerciseType?.value || !isExerciseType(this.selectedExerciseType?.value)) {
      invalidFields.exerciseType = true;
    }

    if (!this.selectedEquipment?.value || !isEquipment(this.selectedEquipment?.value)) {
      invalidFields.equipment = true;
    }

    if (
      !this.selectedPrimaryMuscleGroup?.value ||
      !isMuscleGroup(this.selectedPrimaryMuscleGroup?.value)
    ) {
      invalidFields.primaryMuscleGroup = true;
    }

    return invalidFields;
  }

  isUploadingVideo: boolean = false;

  onUploadVideo = async (file: File) => {
    this.isUploadingVideo = true;

    sendEvent('editExerciseModal_uploadVideo_click', {
      mode: this.mode,
      exerciseName: this.exerciseTitle,
    });

    const videoUrlToBeUploaded = (await getUrlFromFile(file)).url;

    try {
      const fileName = `${localStorageStores.account.username}-${this.exerciseTitle}-${uuid()}`;
      const { url } = await uploadVideo(fileName, videoUrlToBeUploaded);
      this.videoAttachment = {
        url,
        type: 'mp4_video',
      };

      sendEvent('editExerciseModal_uploadVideo_Success', {
        mode: this.mode,
        exerciseName: this.exerciseTitle,
      });
      this._hasUnsavedChanges = true;
    } catch (e) {
      toast.error('Failed to upload video');
      sendEvent('editExerciseModal_uploadVideo_Failure', {
        mode: this.mode,
        exerciseName: this.exerciseTitle,
        error: e,
      });
    } finally {
      this.isUploadingVideo = false;
    }
  };

  closeModal = () => {
    modal.isEditExerciseModalOpen = false;
  };

  onSavePressed = async () => {
    const muscleGroup = this.selectedPrimaryMuscleGroup?.value;
    const exerciseType = this.selectedExerciseType?.value;
    const equipment = this.selectedEquipment?.value;
    const otherMuscles: MuscleGroup[] = this.selectedSecondaryMuscleGroups.map(
      mg => mg.value,
    ) as any;

    if (
      !this.exerciseTitle ||
      !muscleGroup ||
      !equipment ||
      !isMuscleGroup(muscleGroup) ||
      !isExerciseType(exerciseType) ||
      !isEquipment(equipment)
    ) {
      this.showErrors = true;
      return;
    }

    if (localStorageStores.team.hasMoreThanOneMember && this.isEditing) {
      if (this.isCustomExercise) {
        modal.openAlertModal({
          title: 'Edit Custom Exercise',
          body: 'Custom exercises are shared with your team. Editing will change them for all coaches and clients on the team.',
          confirmButtonTitle: 'Edit anyways',
          cancelButtonTitle: 'Cancel',
          handleAlertConfirm: () => this.save(muscleGroup, otherMuscles, exerciseType, equipment),
        });
      } else {
        // If you are editing a bundled exercise, customizations will belong to the team
        modal.openAlertModal({
          title: 'Edit Exercise',
          body: 'Exercises are shared with your team. Editing will change them for all coaches and clients on the team.',
          confirmButtonTitle: 'Edit anyways',
          cancelButtonTitle: 'Cancel',
          handleAlertConfirm: () => this.save(muscleGroup, otherMuscles, exerciseType, equipment),
        });
      }
    } else {
      this.save(muscleGroup, otherMuscles, exerciseType, equipment);
    }
  };

  save = async (
    muscleGroup: MuscleGroup,
    otherMuscles: MuscleGroup[],
    exerciseType: ExerciseType,
    equipment: Equipment,
  ) => {
    this.isSaving = true;
    try {
      sendEvent('editExerciseModal_saveExercise_Press', {
        mode: this.mode,
        exerciseName: this.exerciseTitle,
        hasCustomInstructions: this.instructions !== '',
        hasCustomVideo: !!this.videoAttachment,
      });

      if (this.isCustomExercise) {
        const result = this.editingCustomExerciseTemplateId
          ? await API.updateCustomExerciseTemplate(this.editingCustomExerciseTemplateId, {
              id: this.editingCustomExerciseTemplateId,
              title: this.exerciseTitle,
              muscle_group: muscleGroup,
              other_muscles: otherMuscles,
              equipment_category: equipment,
              custom_exercise_image_url: this.remoteImageUrl,
              thumbnail_url: this.remoteThumbnailUrl,
            })
          : await API.postCustomExerciseTemplate({
              title: this.exerciseTitle,
              muscle_group: muscleGroup,
              other_muscles: otherMuscles,
              exercise_type: exerciseType,
              equipment_category: equipment,
              custom_exercise_image_url: this.remoteImageUrl,
              thumbnail_url: this.remoteThumbnailUrl,
              coach_id: localStorageStores.account.id,
            });

        if (!this.editingCustomExerciseTemplateId) {
          this.editingCustomExerciseTemplateId = result.data.id;
        }
      }

      const exerciseId =
        this.editingCustomExerciseTemplateId || this.editingBundledExerciseTemplateId;

      if (exerciseId) {
        await this.saveExerciseCustomizations(exerciseId);
      }

      await fireAndForget([localStorageStores.exerciseTemplates.fetch()]);
      this._hasUnsavedChanges = false;
      this.showErrors = false;
      this.exerciseTitle = '';
      this.selectedPrimaryMuscleGroup = undefined;
      this.selectedExerciseType = undefined;
      this.selectedEquipment = undefined;
      this.selectedSecondaryMuscleGroups = [];

      if (this.editingCustomExerciseTemplateId) {
        this.onExerciseSavedHandler?.(this.editingCustomExerciseTemplateId);
      }

      sendEvent('editExerciseModal_saveExercise_Success', {
        mode: this.mode,
        exerciseName: this.exerciseTitle,
        hasCustomInstructions: this.instructions !== '',
        hasCustomVideo: !!this.videoAttachment,
      });
      this.closeModal();
      return;
    } catch (e) {
      toast.error('Unable to update exercise');
      sendEvent('editExerciseModal_saveExercise_Failure', {
        mode: this.mode,
        exerciseName: this.exerciseTitle,
        hasCustomInstructions: this.instructions !== '',
        hasCustomVideo: !!this.videoAttachment,
        error: e,
      });
      return;
    } finally {
      // Give the modal a second to close before we set isSaving to false
      setTimeout(() => {
        this.isSaving = false;
      }, 100);
    }
  };

  onCloseRequested = () => {
    if (this._hasUnsavedChanges) {
      modal.openAlertModal({
        title: 'You have unsaved changes',
        body: 'You will lose all the changes made. Are you sure you want to close?',
        confirmButtonTitle: 'Close without saving',
        confirmButtonStyle: 'destructive',
        cancelButtonTitle: 'Keep editing',
        handleAlertConfirm: async () => {
          this.closeModal();
        },
      });
      return false;
    } else {
      this.closeModal();
    }
  };

  saveExerciseCustomizations = async (exerciseId: string) => {
    const video_url = !!this.videoAttachment?.url ? this.videoAttachment.url : undefined;
    const instructions =
      !!this.instructions && this.instructions !== this.defaultExerciseInstructions
        ? this.instructions
        : undefined;

    if (!video_url && !instructions) {
      await API.deleteExerciseCustomizations(exerciseId);
    } else {
      await API.postExerciseCustomization({
        exercise_template_id: exerciseId,
        video_url,
        instructions,
      });
    }

    fireAndForget([localStorageStores.exerciseTemplateCustomizations.fetch()]);
  };

  onFileSystemImageSelected = async (file: File) => {
    this.isUploadingImage = true;
    this._hasUnsavedChanges = true;
    const { url } = await getUrlFromFile(file);

    this.imageUrlToCrop = url;
  };

  onCancelImageCrop = () => {
    this.imageUrlToCrop = undefined;
    this.isUploadingImage = false;
  };

  onUploadImage = async (imageUrl: string) => {
    this.imageUrlToCrop = undefined;
    try {
      const thumbnailFileName = `exercise-thumbnail-${
        localStorageStores.account.username
      }-${uuid()}.jpg`;
      const fileName = `exercise-${localStorageStores.account.username}-${uuid()}.jpg`;

      const thumbnailImageUrl = await resizeImage(imageUrl, { width: 180, height: 180 });

      const { url: remoteThumbnailUrl } = await uploadImage(thumbnailFileName, thumbnailImageUrl);
      const { url: remoteImageUrl } = await uploadImage(fileName, imageUrl);

      this.remoteThumbnailUrl = remoteThumbnailUrl;
      this.remoteImageUrl = remoteImageUrl;
    } catch (e) {
      toast.error('Failed to upload image');
    } finally {
      this.isUploadingImage = false;
    }
  };

  onTitleUpdate = (value: string) => {
    if (value.length > 250) return;

    this.exerciseTitle = value;
    this._hasUnsavedChanges = true;
  };

  selectedExerciseType?: { label: string; value: string };
  onExerciseTypeUpdate = (exerciseType: { label: string; value: string } | undefined) => {
    this.selectedExerciseType = exerciseType;
    this._hasUnsavedChanges = true;
  };

  get exerciseTypeOptions() {
    return customExericseTypes.map(et => ({ label: customExerciseTypeToHuman(et), value: et }));
  }

  selectedEquipment?: { label: string; value: string };
  onEquipmentUpdate = (equipment: { label: string; value: string } | undefined) => {
    this.selectedEquipment = equipment;
    this._hasUnsavedChanges = true;
  };

  get equipmentOptions() {
    return equipments.map(e => ({ label: equipmentToHuman(e), value: e }));
  }

  selectedPrimaryMuscleGroup?: { label: string; value: string };
  onMuscleGroupUpdate = (muscleGroup: { label: string; value: string } | undefined) => {
    this.selectedPrimaryMuscleGroup = muscleGroup;
    this._hasUnsavedChanges = true;
  };

  get muscleGroupOptions() {
    return muscleGroups.map(mg => ({ label: muscleGroupToHuman(mg), value: mg }));
  }

  selectedSecondaryMuscleGroups: Array<{ label: string; value: string }> = [];
  onSecondaryMuscleGroupUpdate = (value: Array<{ label: string; value: string }>) => {
    this.selectedSecondaryMuscleGroups = value;
    this._hasUnsavedChanges = true;
  };
  get secondaryMuscleGroupOptions() {
    return muscleGroups.map(mg => ({ label: muscleGroupToHuman(mg), value: mg }));
  }

  instructions: string = '';
  onInstructionsUpdate = (value: string) => {
    this.instructions = value;
    this._hasUnsavedChanges = true;
  };

  onResetToDefaultInstructions = () => {
    if (this.instructions === this.defaultExerciseInstructions) return;

    this.instructions = this.defaultExerciseInstructions || '';
    this._hasUnsavedChanges = true;
  };

  get isShowingDefaultInstructions(): boolean {
    return this.instructions === this.defaultExerciseInstructions;
  }

  videoAttachment?: VideoAttachment;
  onAddVideoAttachment = (video: VideoAttachment) => {
    if (video.type === 'youtube' && !validateYoutubeUrl(video.url)) {
      sendEvent('editExerciseModal_addVideoAttachment_Failure', {
        mode: this.mode,
        exerciseName: this.exerciseTitle,
        error: 'Invalid youtube url',
      });
      return;
    }

    sendEvent('editExerciseModal_addVideoAttachment_Success', {
      mode: this.mode,
      exerciseName: this.exerciseTitle,
    });

    this.videoAttachment = video;
    this._hasUnsavedChanges = true;
  };

  onRemoveVideoAttachment = () => {
    sendEvent('editExerciseModal_removeVideoAttachment_click', {
      mode: this.mode,
      exerciseName: this.exerciseTitle,
    });

    this.videoAttachment = undefined;
    this._hasUnsavedChanges = true;
  };
}
