import {
  DistanceUnitShort,
  roundToOneDecimal,
  roundToTwoDecimal,
  roundToWholeNumber,
  secondsToWordFormat,
  secondsToWordFormatMinutes,
  WeightUnit,
  WorkoutExercise,
  WorkoutExerciseSet,
} from 'hevy-shared';
import { userDistance, userShortDistance, userWeight } from 'utils/units';

interface Preferences {
  weightUnit: WeightUnit;
  distanceUnitShort: DistanceUnitShort;
}

export const calculateSecondColumnHeader = (e: WorkoutExercise): string => {
  switch (e.exercise_type) {
    case 'weight_reps':
    case 'bodyweight_assisted_reps':
    case 'bodyweight_reps':
      return 'WEIGHT & REPS';
    case 'reps_only':
      return 'REPS';
    case 'distance_duration':
      return 'DISTANCE & TIME';
    case 'duration':
      return 'TIME';
    case 'short_distance_weight':
      return 'WEIGHT & DISTANCE';
    case 'weight_duration':
      return 'WEIGHT & DURATION';
    case 'floors_duration':
      return 'FLOORS & TIME';
    case 'steps_duration':
      return 'STEPS & TIME';
  }
};

export const calculateSetValue = (
  e: WorkoutExercise,
  s: WorkoutExerciseSet,
  p: Preferences,
): string => {
  switch (e.exercise_type) {
    case 'weight_reps':
    case 'bodyweight_reps': {
      const weight = userWeight(s.weight_kg ?? 0, p.weightUnit);
      return `${roundToTwoDecimal(weight)}${p.weightUnit} x ${s.reps} reps`;
    }
    case 'bodyweight_assisted_reps': {
      const assistWeight = userWeight(s.weight_kg ?? 0, p.weightUnit);
      return `-${roundToTwoDecimal(assistWeight)}${p.weightUnit} x ${s.reps} reps`;
    }
    case 'reps_only': {
      return `${s.reps ?? 0} reps`;
    }
    case 'distance_duration': {
      const distance = roundToOneDecimal(userDistance(s.distance_meters ?? 0, p.distanceUnitShort));
      const time = secondsToWordFormatMinutes(s.duration_seconds ?? 0);
      return `${distance}${p.distanceUnitShort} - ${time}`;
    }
    case 'duration': {
      return `${secondsToWordFormat(s.duration_seconds ?? 0)}`;
    }
    case 'short_distance_weight': {
      const weight = userWeight(s.weight_kg ?? 0, p.weightUnit);
      const shortDistance = userShortDistance(s.distance_meters ?? 0);
      const distanceUnits = p.distanceUnitShort === 'km' ? 'm' : 'yrd';
      return `${roundToTwoDecimal(weight)}${p.weightUnit} - ${shortDistance}${distanceUnits}`;
    }
    case 'weight_duration': {
      const weight = userWeight(s.weight_kg ?? 0, p.weightUnit);
      const time = secondsToWordFormatMinutes(s.duration_seconds ?? 0);
      return `${roundToTwoDecimal(weight)}${p.weightUnit} x ${time}`;
    }
    case 'floors_duration': {
      const floors = roundToWholeNumber(s.custom_metric ?? 0);
      const duration = `${secondsToWordFormat(s.duration_seconds ?? 0)}`;
      return `${floors} floors - ${duration}`;
    }
    case 'steps_duration': {
      const steps = roundToWholeNumber(s.custom_metric ?? 0);
      const duration = `${secondsToWordFormat(s.duration_seconds ?? 0)}`;
      return `${steps} steps - ${duration}`;
    }
  }
};
