import dayjs, { Dayjs } from 'dayjs';
import { Weekday } from 'hevy-shared';

export const dateOlderThanHevy = dayjs('2018-07-31T12:53:33.333Z');

export const isValidLastWorkoutDate = (lastWorkoutAt?: string | Dayjs) => {
  if (!lastWorkoutAt) return false;

  const lastWorkoutDate = dayjs(lastWorkoutAt);

  return lastWorkoutDate.isValid() && lastWorkoutDate.isAfter(dateOlderThanHevy);
};

export const weekIntervalString = (startOfWeekDate: string | Dayjs) => {
  const date = dayjs(startOfWeekDate);

  return `${date.format('MMM D')} - ${date
    .add(1, 'week')
    .subtract(1, 'day') // Subtract a day so the end date is the last included day
    .format('MMM DD')}`;
};

export const startOf = (
  interval: 'week' | 'month' | 'year',
  date: string | Dayjs,
  firstWeekday: Weekday,
) => {
  if (interval === 'week') {
    return dayjs(date).startOf('week').add(weekdayNumberMap[firstWeekday], 'day');
  }

  return dayjs(date).startOf(interval);
};

export const endOf = (
  interval: 'week' | 'month' | 'year',
  date: string | Dayjs,
  firstWeekday: Weekday,
) => {
  if (interval === 'week') {
    return dayjs(date).endOf('week').add(weekdayNumberMap[firstWeekday], 'day');
  }

  return dayjs(date).endOf(interval);
};

export const weekdayNumberMap: { [key in Weekday]: number } = {
  sunday: 0,
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
};

export const weekdaySelectOptions = [
  { value: 'sunday', label: 'Sunday' },
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
];

export const secondsToWordFormatHours = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);

  return `${hours}h`;
};

export const FORMATTED_DATE_FORMAT = 'dddd, MMMM D, YYYY';

export const getShortRelativeDate = (secondsAgo: number) => {
  if (secondsAgo < 30) return 'Now';
  if (secondsAgo < 60) return `${Math.floor(secondsAgo)}s`;
  const minutesAgo = Math.floor(secondsAgo / 60);
  if (minutesAgo < 60) return `${minutesAgo}m`;
  const hoursAgo = Math.floor(minutesAgo / 60);
  if (hoursAgo < 24) return `${hoursAgo}h`;
  const daysAgo = Math.floor(hoursAgo / 24);
  if (daysAgo < 7) return `${daysAgo}d`;
  const weeksAgo = Math.floor(daysAgo / 7);
  return `${weeksAgo}w`;
};

export const getRelativeDateWithTime = (date: Dayjs) => {
  const now = dayjs();
  const yesterday = dayjs().subtract(1, 'day');

  if (now.isSame(date, 'day')) {
    return date.fromNow();
  }

  if (yesterday.isSame(date, 'day')) {
    return `Yesterday at ${date.format('h:mm a')}`;
  }

  return date.format(FORMATTED_DATE_FORMAT);
};

export const getRelativeDate = (date: Dayjs) => {
  const now = dayjs();
  const yesterday = dayjs().subtract(1, 'day');

  if (now.isSame(date, 'day')) {
    return 'Today';
  }

  if (yesterday.isSame(date, 'day')) {
    return `Yesterday`;
  }

  return date.format(FORMATTED_DATE_FORMAT);
};

export const daysToWeeks = (days: number) => {
  return Math.floor(days / 7);
};

export const weeksToDays = (weeks: number) => {
  return weeks * 7;
};

export const restTimerOptions = [
  0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100, 105, 110, 115,
  120, 135, 150, 165, 180, 195, 210, 225, 240, 255, 270, 285, 300,
];
