import { getYoutubeVideoId } from 'hevy-shared';

export const getYoutubeEmbedURL = (videoURL: string): string | undefined => {
  const youTubeVideoId = getYoutubeVideoId(videoURL);

  if (!youTubeVideoId) {
    return undefined;
  }

  return `https://www.youtube.com/embed/${youTubeVideoId}?&autoplay=1&mute=1`;
};

export const getYoutubeThumbnailURL = (url: string): string | undefined => {
  const youTubeVideoId = getYoutubeVideoId(url);

  if (!youTubeVideoId) {
    return;
  }

  return `https://img.youtube.com/vi/${youTubeVideoId}/hqdefault.jpg`;
};
