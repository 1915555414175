"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.trimWorkout = exports.isWorkoutCurrentlyPaused = exports.generateWorkoutTimeEvents = exports.calculateEndTime = exports.calculateDuration = void 0;
const _1 = require(".");
const calculateDuration = (normalizedWorkout, nowUnix) => {
    const { startTime, timeEvents } = normalizedWorkout;
    if (!timeEvents.length)
        return nowUnix - startTime;
    const timeBeforeFirstEvent = timeEvents[0].unixTimestamp - startTime;
    const duration = timeEvents.reduce((prev, curr, index, array) => {
        var _a;
        const nextEvent = (_a = array[index + 1]) !== null && _a !== void 0 ? _a : {
            type: 'resume',
            unixTimestamp: nowUnix,
        };
        if (curr.type === 'pause') {
            return prev;
        }
        else {
            return prev + nextEvent.unixTimestamp - curr.unixTimestamp;
        }
    }, timeBeforeFirstEvent);
    return duration;
};
exports.calculateDuration = calculateDuration;
const calculateEndTime = (normalizedWorkout, nowUnix) => {
    const { startTime } = normalizedWorkout;
    const durationSeconds = (0, exports.calculateDuration)(normalizedWorkout, nowUnix);
    return startTime + durationSeconds;
};
exports.calculateEndTime = calculateEndTime;
const generateWorkoutTimeEvents = ({ startTimeUnix, durationSeconds, nowUnix, isPaused = false, }) => {
    const maxPossibleDuration = nowUnix - startTimeUnix;
    const safeRequestedDuration = (0, _1.clampNumber)(durationSeconds, {
        min: 0,
        max: maxPossibleDuration,
    });
    const pausePaddingSeconds = maxPossibleDuration - safeRequestedDuration;
    const timeEvents = [];
    if (pausePaddingSeconds > 0) {
        /**
         * The duration is shorter than (endTime - startTime). Add a pause event at
         * the start of the workout for padding, and a resume event at the correct
         * time to make the duration match.
         */
        timeEvents.push(...[
            { type: 'pause', unixTimestamp: startTimeUnix },
            { type: 'resume', unixTimestamp: startTimeUnix + pausePaddingSeconds },
        ]);
    }
    if (isPaused) {
        timeEvents.push({
            type: 'pause',
            unixTimestamp: startTimeUnix + maxPossibleDuration,
        });
    }
    return timeEvents;
};
exports.generateWorkoutTimeEvents = generateWorkoutTimeEvents;
const isWorkoutCurrentlyPaused = (normalizedWorkout) => {
    var _a;
    return ((_a = normalizedWorkout.timeEvents.slice(-1)[0]) === null || _a === void 0 ? void 0 : _a.type) === 'pause';
};
exports.isWorkoutCurrentlyPaused = isWorkoutCurrentlyPaused;
/**
 * When logging a workout, we can't just use the `completed` state of a set to
 * determine if the user meant for the set to be included in the workout. This
 * is because a lot of users never mark sets as completed, and forcing them to
 * do that wouldn't add anything to the app. For that reason, we count a set as
 * completed if it contains inputted valid values based on its exercise type.
 * This function filters out all sets in a `NormalizedWorkout` that are
 * incomplete based on the values inputted.
 */
const trimWorkout = (workout, exerciseTypeMap) => {
    // Filter out empty sets.
    const filteredSetsWorkout = Object.assign(Object.assign({}, workout), { exercises: workout.exercises.map((e) => {
            return Object.assign(Object.assign({}, e), { sets: e.sets.filter((s) => {
                    const exerciseType = exerciseTypeMap[e.exerciseTemplateId];
                    if (!exerciseType) {
                        /**
                         * if we don't know the exercise type, we can't tell if the set is
                         * empty or not, so we'll just assume that it's not.
                         */
                        return true;
                    }
                    return !isEmptySet(s, exerciseType);
                }) });
        }) });
    // Filter out exercises that contain 0 sets.
    const filteredWorkout = Object.assign(Object.assign({}, filteredSetsWorkout), { exercises: filteredSetsWorkout.exercises.filter((e) => !!e.sets.length) });
    return filteredWorkout;
};
exports.trimWorkout = trimWorkout;
const isEmptySet = (set, exerciseType) => {
    switch (exerciseType) {
        case 'weight_reps':
        case 'bodyweight_reps':
        case 'bodyweight_assisted_reps':
        case 'reps_only':
            return !set.reps && !set.weight;
        case 'distance_duration':
        case 'duration':
            return !set.duration && !set.distance;
        case 'short_distance_weight':
            return !set.distance && !set.weight;
        case 'weight_duration':
            return !set.weight && !set.duration;
        case 'floors_duration':
        case 'steps_duration':
            return !set.duration;
    }
};
