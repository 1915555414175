"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidUserWorkoutMetricsType = exports.isBodyMeasurementKey = exports.measurementsList = exports.isPublicWorkout = exports.isSetType = exports.isRPE = exports.validRpeValues = exports.supportedInstructionsLanguages = exports.isExerciseType = exports.customExericseTypes = exports.exerciseTypes = exports.isExerciseRepType = exports.exerciseRepTypes = exports.isEquipmentFilter = exports.equipmentFilters = exports.isEquipment = exports.equipments = exports.isMuscleGroupFilter = exports.muscleGroupFilters = exports.isMuscleGroup = exports.muscleGroups = exports.DefaultClientConfiguration = exports.isCoachRole = exports.isErrorResponse = exports.isLivePRVolumeOption = exports.isTimerVolumeOption = exports.isWeekday = exports.orderedWeekdays = exports.isBodyMeasurementUnit = exports.isDistanceUnitShort = exports.isDistanceUnit = exports.isWeightUnit = exports.isLanguage = exports.supportedLanguages = void 0;
const typeUtils_1 = require("./typeUtils");
__exportStar(require("./constants"), exports);
__exportStar(require("./utils"), exports);
__exportStar(require("./units"), exports);
__exportStar(require("./filterExercises"), exports);
__exportStar(require("./setIndicatorUtils"), exports);
__exportStar(require("./workoutVolume"), exports);
__exportStar(require("./coachPlans"), exports);
__exportStar(require("./chat"), exports);
__exportStar(require("./websocket"), exports);
__exportStar(require("./cue"), exports);
__exportStar(require("./muscleHeatmaps"), exports);
__exportStar(require("./muscleSplits"), exports);
__exportStar(require("./notifications"), exports);
__exportStar(require("./normalizedWorkoutUtils"), exports);
__exportStar(require("./routineUtils"), exports);
__exportStar(require("./typeUtils"), exports);
__exportStar(require("./async"), exports);
exports.supportedLanguages = [
    'en',
    'es',
    'de',
    'fr',
    'it',
    'pt',
    'tr',
    'zh_CN',
    'zh_TW',
    'ru',
    'ja',
    'ko',
];
const isLanguage = (x) => (0, typeUtils_1.isInArray)(x, exports.supportedLanguages);
exports.isLanguage = isLanguage;
const isWeightUnit = (x) => {
    return x === 'kg' || x === 'lbs';
};
exports.isWeightUnit = isWeightUnit;
const isDistanceUnit = (x) => {
    return x === 'kilometers' || x === 'miles';
};
exports.isDistanceUnit = isDistanceUnit;
const isDistanceUnitShort = (x) => {
    return x === 'km' || x === 'mi';
};
exports.isDistanceUnitShort = isDistanceUnitShort;
const isBodyMeasurementUnit = (x) => {
    return x === 'cm' || x === 'in';
};
exports.isBodyMeasurementUnit = isBodyMeasurementUnit;
/** Monday is first */
exports.orderedWeekdays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
];
const isWeekday = (x) => (0, typeUtils_1.isInArray)(x, exports.orderedWeekdays);
exports.isWeekday = isWeekday;
const _timerVolumeOptions = ['off', 'low', 'normal', 'high'];
const isTimerVolumeOption = (x) => (0, typeUtils_1.isInArray)(x, _timerVolumeOptions);
exports.isTimerVolumeOption = isTimerVolumeOption;
const _livePRVolumeOption = ['off', 'low', 'normal', 'high'];
const isLivePRVolumeOption = (x) => (0, typeUtils_1.isInArray)(x, _livePRVolumeOption);
exports.isLivePRVolumeOption = isLivePRVolumeOption;
const isErrorResponse = (x) => {
    return typeof (x === null || x === void 0 ? void 0 : x.error) === 'string';
};
exports.isErrorResponse = isErrorResponse;
const _coachRoles = ['member', 'admin', 'owner'];
const isCoachRole = (role) => (0, typeUtils_1.isInArray)(role, _coachRoles);
exports.isCoachRole = isCoachRole;
exports.DefaultClientConfiguration = {
    is_chat_enabled: false,
    are_notifications_enabled: true,
};
const _supportedProStores = [
    'app_store',
    'play_store',
    'stripe',
    'hevy-gift',
    'gympass',
    'paddle',
    'hevy-coach',
];
exports.muscleGroups = [
    'abdominals',
    'abductors',
    'adductors',
    'biceps',
    'lower_back',
    'upper_back',
    'cardio',
    'chest',
    'calves',
    'forearms',
    'glutes',
    'hamstrings',
    'lats',
    'quadriceps',
    'shoulders',
    'triceps',
    'traps',
    'neck',
    'full_body',
    'other',
];
const isMuscleGroup = (x) => (0, typeUtils_1.isInArray)(x, exports.muscleGroups);
exports.isMuscleGroup = isMuscleGroup;
exports.muscleGroupFilters = ['all_muscles', ...exports.muscleGroups];
const isMuscleGroupFilter = (x) => (0, typeUtils_1.isInArray)(x, exports.muscleGroupFilters);
exports.isMuscleGroupFilter = isMuscleGroupFilter;
/**
 * Equipment
 */
exports.equipments = [
    'none',
    'barbell',
    'dumbbell',
    'kettlebell',
    'machine',
    'plate',
    'resistance_band',
    'suspension',
    'other',
];
const isEquipment = (x) => (0, typeUtils_1.isInArray)(x, exports.equipments);
exports.isEquipment = isEquipment;
exports.equipmentFilters = ['all_equipment', ...exports.equipments];
const isEquipmentFilter = (x) => (0, typeUtils_1.isInArray)(x, exports.equipmentFilters);
exports.isEquipmentFilter = isEquipmentFilter;
exports.exerciseRepTypes = [
    'weight_reps',
    'reps_only',
    'bodyweight_reps', // This is actually used for weighted bodyweight exercises (eg weighted pull ups)
    'bodyweight_assisted_reps',
];
const isExerciseRepType = (x) => (0, typeUtils_1.isInArray)(x, exports.exerciseRepTypes);
exports.isExerciseRepType = isExerciseRepType;
exports.exerciseTypes = [
    ...exports.exerciseRepTypes,
    'duration',
    'weight_duration',
    'distance_duration',
    /**
     * Note on short_distance_weight exercises. The UI makes it look like we support yards and meters
     * inputs, but in reality all inputs are considered to be meters. For example, if an imperial user inputs
     * 10 yards it'll be stored as 10 meters. It's mostly to account for the weird volume calculations that
     * would happen if we stored exact values in yards. The volume of a weight_reps set is weight*reps,
     * the volume of a short_distance_weight is weight*distance_meters if we converted yards to meters
     * imperial users would get a different volume value than metric users. To keep it simple and since
     * a meter is basically the same as a yard, we're not storing the value in yards and we convert 1-1
     * from yards<->meters.
     */
    'short_distance_weight',
    /**
     * At the time of writing, the only exercise that uses these types are Stair Machine (Floors)
     * and Stair Machine (Steps) respectively.
     */
    'floors_duration',
    'steps_duration',
];
exports.customExericseTypes = exports.exerciseTypes.filter((type) => type !== 'floors_duration' && type !== 'steps_duration');
const isExerciseType = (x) => (0, typeUtils_1.isInArray)(x, exports.exerciseTypes);
exports.isExerciseType = isExerciseType;
exports.supportedInstructionsLanguages = [
    'en',
    'it',
    'de',
    'es',
    'fr',
    'pt',
];
/** Workouts */
const _setPersonalRecordTypes = [
    'best_weight',
    'best_reps',
    'best_volume',
    'best_1rm',
    'best_duration',
    'best_distance',
];
exports.validRpeValues = [6, 7, 7.5, 8, 8.5, 9, 9.5, 10];
const isRPE = (x) => (0, typeUtils_1.isInArray)(x, exports.validRpeValues);
exports.isRPE = isRPE;
const _setTypes = ['warmup', 'normal', 'failure', 'dropset'];
const isSetType = (x) => (0, typeUtils_1.isInArray)(x, _setTypes);
exports.isSetType = isSetType;
const isPublicWorkout = (x) => {
    return x.type === 'public';
};
exports.isPublicWorkout = isPublicWorkout;
exports.measurementsList = [
    'weight_kg',
    'fat_percent',
    'neck_cm',
    'shoulder_cm',
    'chest_cm',
    'left_bicep_cm',
    'right_bicep_cm',
    'left_forearm_cm',
    'right_forearm_cm',
    'abdomen',
    'waist',
    'hips',
    'left_thigh',
    'right_thigh',
    'left_calf',
    'right_calf',
];
const isBodyMeasurementKey = (key) => (0, typeUtils_1.isInArray)(key, exports.measurementsList);
exports.isBodyMeasurementKey = isBodyMeasurementKey;
const _validUserWorkoutMetricsTypes = ['duration', 'reps'];
const isValidUserWorkoutMetricsType = (x) => (0, typeUtils_1.isInArray)(x, _validUserWorkoutMetricsTypes);
exports.isValidUserWorkoutMetricsType = isValidUserWorkoutMetricsType;
