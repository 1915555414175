import { ProfileImage } from 'components/ProfileImage';
import { observer } from 'mobx-react-lite';
import Link from 'next/link';
import router from 'next/router';
import React from 'react';
import { localStorageStores } from 'state/localStorageStores';
import { DividerLine, VerticalDividerLine } from 'styleguide/DividerLine';
import { CustomizableIcon } from 'styleguide/CustomizableIcon';
import { View } from 'styleguide/View';
import { Spacing } from 'styleguide/spacing';
import { refreshUserContent } from 'utils/fetchUserContent';
import { clearDataStores, logout } from 'utils/logout';
import { NavBarRoute } from './NavBarRoute';
import { NavBarSearch } from './NavBarSearch';
import { NavBarUserSearchViewModel } from './NavBarSearchViewModel';
import Settings from 'components/assets/settings.svg';
import Grow from 'components/assets/grow.svg';
import ProgramLibrary from 'components/assets/program-library.svg';
import Clients from 'components/assets/clients.svg';
import ExerciseLibrary from 'components/assets/dumbbell.svg';
import Chat from 'components/assets/chat.svg';
import Dashboard from 'components/assets/dashboard.svg';
import Team from 'components/assets/team.svg';
import { colors } from 'styleguide/colors';
import styled from 'styled-components';
import { modal } from 'components/Modals/ModalManager';
import { FreeTrialWidget } from './FreeTrialWidget';
import { AnchorText, PrimaryButton, TextButton } from 'styleguide/Buttons';
import { sendEvent } from 'utils/analyticsEvents';
import { TextMD, TextMDRegular, TextXSRegular } from 'styleguide/Texts';
import { ShowOnDesktopDiv } from 'components/mobileVsDesktopShowHideHelpers';
import { FlexRow } from 'styleguide/Row';
import { ChatAggregator } from 'state/aggregators/chatAggregatorStore';
import { PlanAggregator } from 'state/aggregators/planAggregatorStore';

const NavOptionsContainer = styled(View)`
  flex: 1;
  width: 100%;
  overflow-y: auto;
`;

const NavBarFooterContainer = styled(View)`
  flex: 1;
  justify-content: flex-end;
  color: ${colors.white};
`;

const ProfileLink = observer(() => {
  return (
    <Link
      onClick={() => {
        modal.isMobileNavBarModalOpen = false;
      }}
      href="/settings/profile"
      style={{
        textDecoration: 'none',
      }}
    >
      <FlexRow style={{ gap: Spacing.sm + Spacing.xs }}>
        <ProfileImage source={localStorageStores.account.profile_pic} diameter={40} />
        <TextMDRegular
          style={{
            color: colors.neutral200,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            maxWidth: 125,
          }}
        >
          {localStorageStores.account.personalCoachName}
        </TextMDRegular>
      </FlexRow>
    </Link>
  );
});

const LogoutButton = () => {
  return (
    <View style={{ flexDirection: 'row' }}>
      <View
        onClick={() => {
          modal.isMobileNavBarModalOpen = false;
          if (localStorageStores.auth.isImpersonating) {
            localStorageStores.auth.stopImpersonatingUser();
            clearDataStores();
            refreshUserContent().then(() => router.reload());
          } else {
            logout('desktop-navBar');
          }
        }}
        style={{ cursor: 'pointer' }}
      >
        <CustomizableIcon
          style={{ height: 40, width: 40, paddingTop: Spacing.xs }}
          type="logout"
          tint={colors.white}
        ></CustomizableIcon>
      </View>
    </View>
  );
};

const SendFeedbackButton = observer(() => {
  return (
    <TextButton
      title="Feedback"
      onClick={() => {
        modal.isMobileNavBarModalOpen = false;
        modal.openFeedbackModal();
        sendEvent('navBar_sendFeedback_click');
      }}
    />
  );
});

const HelpButton = () => {
  return (
    <FlexRow
      onClick={() => {
        sendEvent('navBar_help_click');
      }}
    >
      <AnchorText text="Help" target="_blank" URL={'https://help.hevycoach.com/'} />
    </FlexRow>
  );
};

export const NavBarMainContent = observer(
  ({ vm, pathname }: { vm: NavBarUserSearchViewModel; pathname: string }) => {
    const DashboardIcon = (tint: string) => <Dashboard stroke={tint} />;
    const ClientsIcon = (tint: string) => <Clients stroke={tint} />;
    const ProgramLibraryIcon = (tint: string) => <ProgramLibrary stroke={tint} />;
    const ExerciseLibraryIcon = (tint: string) => <ExerciseLibrary stroke={tint} />;
    const ChatIcon = (tint: string) => <Chat stroke={tint} />;
    const SettingsIcon = (tint: string) => <Settings fill={tint} />;
    const GrowIcon = (tint: string) => <Grow stroke={tint} />;
    const TeamIcon = (tint: string) => <Team stroke={tint} />;

    const hasExpiredTrial =
      localStorageStores.plan.currentPlan?.type === 'trial' &&
      localStorageStores.plan.currentPlan?.status === 'paused';

    const isTeamPastPlanLimits = PlanAggregator.isTeamPastPlanLimits;

    return (
      <NavOptionsContainer>
        <ShowOnDesktopDiv>
          {/* Mobile search is in the top bar */}
          <NavBarSearch vm={vm} />
        </ShowOnDesktopDiv>
        <NavBarRoute
          title="Dashboard"
          getIcon={DashboardIcon}
          isSelected={pathname.startsWith('/dashboard') || pathname === '/'}
          path="/"
          disabled={hasExpiredTrial || isTeamPastPlanLimits}
        />
        <NavBarRoute
          title="Clients"
          getIcon={ClientsIcon}
          isSelected={pathname.startsWith('/clients')}
          path="/clients"
          disabled={hasExpiredTrial || isTeamPastPlanLimits}
        />
        <NavBarRoute
          title="Program Library"
          getIcon={ProgramLibraryIcon}
          isSelected={pathname.startsWith('/programs') || pathname.startsWith('/routines')}
          path="/programs"
          disabled={hasExpiredTrial || isTeamPastPlanLimits}
        />
        <NavBarRoute
          title="Exercise Library"
          getIcon={ExerciseLibraryIcon}
          isSelected={pathname.startsWith('/exercise')}
          path="/exercises"
          disabled={hasExpiredTrial || isTeamPastPlanLimits}
        />
        <NavBarRoute
          title="Chat"
          getIcon={ChatIcon}
          isSelected={pathname.startsWith('/chat')}
          path="/chat"
          badgeValue={ChatAggregator.totalUnreadMessages}
          disabled={hasExpiredTrial || isTeamPastPlanLimits}
        />
        <NavBarRoute
          title="Grow"
          getIcon={GrowIcon}
          isSelected={pathname.startsWith('/grow')}
          path="/grow"
          badgeValue={localStorageStores.clientLeads.leads.length}
          disabled={hasExpiredTrial || isTeamPastPlanLimits}
        />
        <NavBarRoute
          title="Settings"
          getIcon={SettingsIcon}
          isSelected={pathname.startsWith('/settings')}
          path="/settings/profile"
        />
        <DividerLine
          style={{ backgroundColor: colors.sideMenuLinesAndBorders, marginBottom: Spacing.sm }}
        />
        <NavBarRoute
          title="Team"
          getIcon={TeamIcon}
          isSelected={pathname.startsWith('/team')}
          path="/team"
          disabled={hasExpiredTrial || isTeamPastPlanLimits}
        />
        <NavBarFooterContainer>
          <FlexRow
            style={{
              justifyContent: 'center',
              gap: Spacing.lg,
              marginBottom: Spacing.md,
            }}
          >
            <SendFeedbackButton />
            <VerticalDividerLine style={{ backgroundColor: colors.neutral700 }} />
            <HelpButton />
          </FlexRow>
          {localStorageStores.plan.trialDaysLeft !== undefined && (
            <FreeTrialWidget daysLeft={localStorageStores.plan.trialDaysLeft} />
          )}

          {localStorageStores.plan.isEligibleToStartFreeTrial && (
            <>
              <DividerLine style={{ backgroundColor: colors.neutral700 }} />
              <View style={{ padding: Spacing.md, gap: Spacing.sm }}>
                <TextMD style={{ color: colors.white }}>Upgrade Plan</TextMD>
                <TextXSRegular style={{ color: colors.white }}>
                  Invite more clients and take your coaching to the next level.
                </TextXSRegular>
                <PrimaryButton
                  title="Start Free Trial"
                  onClick={() => {
                    sendEvent('navbar_startFreeTrial_clicked');
                    modal.isMobileNavBarModalOpen = false;
                    modal.openUpgradePlanModal({
                      source: 'navbar',
                      onClose: upgraded => {
                        if (upgraded) {
                          router.reload();
                        }
                      },
                    });
                  }}
                />
              </View>
            </>
          )}
          <DividerLine style={{ backgroundColor: colors.sideMenuLinesAndBorders }} />
          <FlexRow
            style={{
              padding: `${Spacing.md}px ${Spacing.sm}px`,
              justifyContent: 'space-between',
            }}
          >
            <ProfileLink />
            <LogoutButton />
          </FlexRow>
        </NavBarFooterContainer>
      </NavOptionsContainer>
    );
  },
);
