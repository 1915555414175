import axios from 'axios';
import { env } from 'env';
import Cookies from 'js-cookie';
import {
  UserPreferences,
  AccountUpdate,
  Workout,
  CustomExercise,
  CreateCustomExerciseRequest,
  RoutineUpdate,
  RoutineLocationUpdate,
  PublicWorkout,
  WorkoutComment,
  UserExerciseSet,
  Program,
  ProgramUpdate,
  CoachsClientData,
  ClientRecentExerciseData,
  BodyMeasurement,
  CoachLoginResult,
  CoachSocialLoginResult,
  UpdateCustomExerciseRequest,
  CreateSampleClientResponse,
  ErrorResponse,
  ExerciseTemplateCustomization,
  CreateOrUpdateExerciseTemplateCustomizationRequest,
  BaseRoutine,
  CoachsShallowLibraryRoutine,
  ProgramFolder,
  ProgramLocationUpdate,
  ProgramFolderLocationUpdate,
  PostMessageRequest,
  Conversation,
  PostCreateConversationResponse,
  PostMessageResponse,
  GetLatestMessageFromAllClientsResponse,
  PostGetMessagesRequest,
  PostGetMessagesResponse,
  ClientConfiguration,
  CoachAccountConfiguration,
  PostSetConversationReadAtRequest,
  WeeklyActiveClients,
  ClientActivity,
  RoutineFolder,
  RoutineSync,
  CoachInviteSearchUser,
  OutstandingInvitesForCoachResponse,
  CoachAccount,
  CoachRole,
  OutstandingInvitesForCoachTeamResponse,
  CoachTeam,
  PostAssignProgramRequest,
  GetTeamInviteResponse,
  CoachsLead,
  CoachJoinFormData,
  UserKeyValues,
  PostBodyMeasurementRequest,
  Feedback,
} from 'hevy-shared';
import {
  GetAvailableCoachPlansResponse,
  GetCoachPaymentHistoryResponse,
  GetCoachPlanResponse,
} from 'hevy-shared/built/coachPlans';

interface APIData<T> {
  data: T;
}

const api = axios.create({
  baseURL: env.apiUrl,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': env.apiKey,
    'Hevy-Platform': 'coach-web',
  },
});

const addResponseInterceptor = (
  onRejected: (error: any) => any,
  onFulfilled?: (value: any) => any,
) => {
  api.interceptors.response.use(
    value => {
      onFulfilled?.(value);
      return value;
    },
    error => {
      onRejected(error);
      return Promise.reject(error);
    },
  );
};

let authToken: string | undefined = Cookies.get('auth-token');
const setAuthToken = (newAuthToken?: string) => {
  authToken = newAuthToken;
};

const requestHeadersModifier =
  (withHeaders: any) =>
  (config: any): any => {
    const headers = config.headers || {};
    const modification = withHeaders(headers);

    return modification ? { ...config, headers: { ...(headers || {}), ...modification } } : config;
  };

const authenticationRequestInterceptor = requestHeadersModifier(() => {
  return authToken ? { 'auth-token': authToken } : null;
});

api.interceptors.request.use(authenticationRequestInterceptor);

const signup = (
  username: string,
  email: string,
  password: string,
  isTermsAccepted: boolean,
  inviteShortId?: string,
) => api.post('coach/signup', { username, email, password, isTermsAccepted, inviteShortId });

const login = (
  emailOrUsername: string,
  password: string,
  inviteShortId?: string,
): Promise<APIData<CoachLoginResult>> =>
  api.post('coach/login', { emailOrUsername, password, inviteShortId });
const loginWithTempToken = (temporaryToken: string): Promise<APIData<CoachLoginResult>> =>
  api.post(`/coach/login_with_temp_token`, {}, { headers: { 'temp-auth-token': temporaryToken } });
const signupFromTemporaryToken = (temporaryToken: string): Promise<APIData<CoachLoginResult>> =>
  api.post(`/coach/signup_with_temp_token`, {}, { headers: { 'temp-auth-token': temporaryToken } });
const getMetadataFromTemporaryToken = (
  temporaryToken: string,
): Promise<
  APIData<{
    username: string;
    email: string;
    profile_pic?: string;
    full_name?: string;
    is_user_a_coach: boolean;
  }>
> =>
  api.get(`/user/coach_signup_user_metadata`, { headers: { 'temp-auth-token': temporaryToken } });
const signInWithApple = (
  identityToken: string,
  email?: string,
  inviteShortId?: string,
): Promise<APIData<CoachSocialLoginResult>> =>
  api.post('/coach/sign_in_with_apple_web', {
    identityToken,
    email,
    invite_short_id: inviteShortId,
  });

const signInWithGoogle = (
  code: string,
  inviteShortId?: string,
): Promise<APIData<CoachSocialLoginResult>> =>
  api.post('/coach/sign_in_with_google_web', {
    code,
    invite_short_id: inviteShortId,
  });

const getUserKeyValues = (): Promise<APIData<UserKeyValues>> => api.get('user_key_values');

const updateUserKeyValues = (newKeyValues: UserKeyValues) =>
  api.put('user_key_values', newKeyValues);

const deleteUserKeyValue = (keyToDelete: string) => api.delete(`user_key_value/${keyToDelete}`);

const generatePasswordRecoveryEmail = (email: string) => api.post('recover_password', { email });
const generateDownloadLinkEmail = () => api.post('email_download_link');
const generateContinueOnDesktopEmail = () => api.post('coach/send_continue_on_desktop_email');

const getAccount = (): Promise<APIData<CoachAccount>> => api.get('coach/account');

const getPlan = (): Promise<APIData<GetCoachPlanResponse>> => api.get('/coach/plan');
const getPaymentHistory = (): Promise<APIData<GetCoachPaymentHistoryResponse>> =>
  api.get('/coach/payment_history');
const getAvailablePlans = (): Promise<APIData<GetAvailableCoachPlansResponse>> =>
  api.get('/coach/available_plan_options');
const updateAccount = (account: AccountUpdate) => api.put('account', { account });

const getPresignedUrl = (fileName: string): Promise<APIData<{ presigned_url: string }>> =>
  api.post('presigned_url', { file_name: fileName });

const getPresignedExerciseVideoUrl = (): Promise<APIData<{ presigned_url: string }>> =>
  api.post('/coach/presigned_url_exercise_template_video');

const getPresignedChatFileUrl = (request: {
  content_type: string;
  file_extension: string;
}): Promise<APIData<{ presigned_url: string }>> => api.post('/chat/presigned_url', request);

const updateUsername = (username: string) => api.put('username', { username });

const uploadFileWithPresignedUrl = ({ presignedUrl, file }: { presignedUrl: string; file: File }) =>
  axios.put(presignedUrl, file);

const updateEmail = (email: string) => api.put('email', { email });

const getUserPreferences = (): Promise<APIData<UserPreferences>> => api.get('user_preferences');
const updateUserPreferences = (
  props: Omit<UserPreferences, 'username'>,
): Promise<APIData<UserPreferences>> => api.put('user_preferences', props);

const getPrograms = (coachId?: string): Promise<APIData<Program[]>> =>
  api.get(!!coachId ? `coach/get_coachs_programs/${coachId}` : `coach/get_coachs_programs/`);
const getProgram = (programId: string): Promise<APIData<Program>> =>
  api.get(`coach/program/${programId}`);

const postProgram = (program: ProgramUpdate): Promise<APIData<string>> =>
  api.post('/coach/create_program', { program });
const duplicateProgram = (programId: string): Promise<APIData<string>> =>
  api.post('/coach/duplicate_program', { programId });
const saveProgramAsTemplate = (programId: string): Promise<APIData<string>> =>
  api.post('/coach/save_program', { programId });
const updateProgram = (program: ProgramUpdate, programId: string) =>
  api.put(`/coach/update_program/${programId}`, { program });
const deleteProgram = (programId: string) => api.delete(`/coach/program/${programId}`);
const deleteClientInviteByShortId = (shortId: string) => api.delete(`/coach/invites/${shortId}`);
const postAddRoutineToProgram = (props: { routineId: string; programId: string }) =>
  api.post('/coach/add_routine_to_program', props);

const assignProgramToClient = (props: PostAssignProgramRequest) =>
  api.post('/coach/assign_program_to_clients', props);

const getClients = (): Promise<APIData<CoachsClientData[]>> => api.get('coach/get_coachs_clients');

const getInvites = (): Promise<APIData<OutstandingInvitesForCoachResponse>> =>
  api.get('coach/invites');
const getOrgInvites = (): Promise<APIData<OutstandingInvitesForCoachTeamResponse>> =>
  api.get('coach/team/invites');
const postCoachRole = (request: { targetCoachId: string; targetCoachRole: any }) =>
  api.post('/coach/update_coach_role', request);
const postRemoveCoachFromTeam = (request: { targetCoachId: string }) =>
  api.post('/coach/remove_coach_from_team', request);
const postReassignClients = (request: {
  targetCoachId: string;
  targetClientIds: string[];
  keepProgram: boolean;
}) => api.post('/coach/reassign_clients', request);

const getClientsWorkoutHistory = (
  startTimestamp: number,
  endTimestamp: number | undefined,
): Promise<APIData<{ [user_id: string]: { workout_end_unix: number }[] }>> =>
  api.get('coach/get_coachs_clients_workout_history_for_time_period', {
    params: { start_unix: startTimestamp, end_unix: endTimestamp },
  });

const getClientExerciseHistory = (
  clientId: string,
  exerciseTemplateId: string,
  lessThanIndex?: number,
): Promise<APIData<Workout[]>> =>
  api.get(
    `coach/clients_exercise_history_paged/${clientId}/${exerciseTemplateId}/${lessThanIndex ?? ''}`,
  );

const getClientsActivitiesPaged = (
  lessThanIndex?: number,
  forCoachIds?: string[],
): Promise<APIData<{ activities: ClientActivity[]; oldestActivityIndex: number | null }>> => {
  return api.post(`coach/clients_activities_paged/${lessThanIndex}`, { forCoachIds });
};

const getWeeklyActiveClients = (forCoachId?: string): Promise<APIData<WeeklyActiveClients[]>> => {
  return api.get(`coach/weekly_active_clients${!!forCoachId ? `/${forCoachId}` : ''}`);
};

const getClientsWorkoutsPaged = (
  clientId: string,
  params?: {
    limit?: number;
    offset?: number;
  },
): Promise<APIData<{ workouts: Workout[] }>> =>
  api.get(`coach/clients_workouts_paged/${clientId}`, { params });

const getClientWorkout = (workoutId: string): Promise<APIData<Workout>> =>
  api.get(`coach/client_workout/${workoutId}`);

const deleteClient = (clientId: string): Promise<void> =>
  api.post(`coach/clients/delete/${clientId}`);

const getCoachInfoForInvite = (inviteShortId: string): Promise<APIData<GetTeamInviteResponse>> =>
  api.get(`/coach/team/invite/${inviteShortId}`);
const createClientInvite = (
  inviteeEmailOrUsername: string,
  targetCoachId?: string,
): Promise<void> => api.post(`coach/client_invite/${inviteeEmailOrUsername}`, { targetCoachId });
const createOrgInvite = ({
  inviteeRole,
  inviteeEmail,
  inviteeUsername,
}: {
  inviteeRole: CoachRole;
  inviteeEmail?: string;
  inviteeUsername?: string;
}): Promise<void> => api.post(`coach/team_invite`, { inviteeEmail, inviteeRole, inviteeUsername });
const deleteOrgInvite = ({ inviteShortId }: { inviteShortId: string }): Promise<void> =>
  api.delete(`coach/team_invite/${inviteShortId}`);

const searchUserByEmail = (email: string): Promise<APIData<CoachInviteSearchUser[]>> =>
  api.get(`coach/user_by_email/${email}`);

const searchUserByName = (usernameOrFullName: string): Promise<APIData<CoachInviteSearchUser[]>> =>
  api.get(`coach/user_by_name/${usernameOrFullName}`);

const postCustomExerciseTemplate = (
  exercise: CreateCustomExerciseRequest,
): Promise<APIData<{ id: string }>> => api.post('custom_exercise_template', { exercise });
const deleteCustomExerciseTemplate = (templateId: string) =>
  api.delete(`/custom_exercise_template/${templateId}`);

const postExerciseCustomization = (
  request: CreateOrUpdateExerciseTemplateCustomizationRequest,
): Promise<void> => api.post(`coach/exercise_customization`, request);
const getExerciseCustomizations = (): Promise<APIData<ExerciseTemplateCustomization[]>> =>
  api.get(`/coach/exercise_customizations`);
const deleteExerciseCustomizations = (parentExerciseTemplateId: string) =>
  api.delete(`coach/exercise_customization/${parentExerciseTemplateId}`);

const getProgramRoutines = (programId: string): Promise<APIData<BaseRoutine[]>> =>
  api.get(`/coach/program/${programId}/routines`);

const getRoutine = (routineId: string): Promise<APIData<{ routine: BaseRoutine }>> =>
  api.get(`/routine/${routineId}`);

const getHevyRoutineFolders = (): Promise<APIData<RoutineFolder[]>> => api.get('/routine_folders');

const getHevyRoutinesSync = (routineIdUpdatedAtMap: {
  [routineId: string]: string;
}): Promise<APIData<RoutineSync>> => api.post('routines_sync_batch', routineIdUpdatedAtMap);

const getSimplifiedLibraryRoutines = (
  coachId?: string,
): Promise<APIData<CoachsShallowLibraryRoutine[]>> =>
  api.get(!!coachId ? `/coach/library_routines/${coachId}` : `/coach/library_routines/`);

const postCoachRoutine = (routine: RoutineUpdate): Promise<APIData<{ routineId?: string }>> =>
  api.post('/coach/routine', { routine });
const createClientRoutine = (
  routine: RoutineUpdate,
  clientId: string,
): Promise<APIData<{ routineId?: string }>> =>
  api.post(`/coach/client_routine/${clientId}`, { routine });

const updateClientConfiguration = (updates: { config: ClientConfiguration; client_id: string }[]) =>
  api.post(`/coach/client_configuration`, updates);
/**
 * CoachConfiguration
 */
const getConfig = (): Promise<APIData<CoachAccountConfiguration>> => api.get('/coach/config');
const setConfig = (config: CoachAccountConfiguration) => api.post('/coach/config', config);

const postCoachRoutineCopy = (props: {
  routineId: string;
  ignoreValues: boolean;
  index: number;
  copiedRoutineTitle?: string;
}) => api.post('/coach/routine_copy', props);
const postUpdateClientNotes = (notes: string, clientId: string) =>
  api.post(`/coach/client_notes/${clientId}`, { notes });
const postUpdateJoinForm = (message?: string, bannerImageUrl?: string) =>
  api.post(`/coach/join_form`, { message: message, bannerImageUrl: bannerImageUrl });

const getCoachJoinFormMetadata = (coachUsername: string): Promise<APIData<CoachJoinFormData>> =>
  api.get(`/coach/join_form_metadata/${coachUsername}`);

const postCreateCoachLead = (
  coachUsername: string,
  leadFullname: string,
  leadEmail: string,
  leadMessage: string,
): Promise<APIData<CoachJoinFormData>> =>
  api.post(`/coach/create_lead/${coachUsername}`, { leadFullname, leadEmail, leadMessage });

const getCoachLeads = (): Promise<APIData<CoachsLead[]>> => api.get(`/coach/leads`);
const acceptCoachLead = (leadEmailAddress: string): Promise<APIData<any[]>> =>
  api.get(`/coach/leads/${leadEmailAddress}`);
const deleteCoachLead = (leadEmailAddress: string): Promise<APIData<any[]>> =>
  api.delete(`/coach/leads/${leadEmailAddress}`);

const createSampleClient = (): Promise<APIData<CreateSampleClientResponse>> =>
  api.post(`/coach/create_sample_client`);
const updateCoachRoutine = (routineId: string, routine: RoutineUpdate) =>
  api.put(`/coach/routine/${routineId}`, { routine });
const updateClientRoutine = (routineId: string, routine: RoutineUpdate) =>
  api.put(`/coach/update_client_routine/${routineId}`, { routine });
const updateCoachRoutineLocations = (locations: RoutineLocationUpdate[]) =>
  api.put('/coach/routine_locations', { locations });
const updateClientsRoutineLocations = (locations: RoutineLocationUpdate[], clientId: string) =>
  api.put(`/coach/routine_locations/${clientId}`, { locations });

const deleteClientRoutine = (routineId: string) => api.delete(`/coach/client_routine/${routineId}`);
const deleteCoachRoutine = (routineId: string) => api.delete(`/coach/coach_routine/${routineId}`);

const getCustomExerciseTemplates = (): Promise<APIData<CustomExercise[]>> =>
  api.get('/coach/custom_exercise_templates');

const getConversations = (): Promise<
  APIData<{
    conversations: Conversation[];
  }>
> => api.get('/coach/conversations');

const getLatestMessageFromClientConversations = (): Promise<
  APIData<GetLatestMessageFromAllClientsResponse>
> => api.get('/coach/latest_conversation_messages');

const createMessage = (request: PostMessageRequest): Promise<APIData<PostMessageResponse>> => {
  return api.post('/coach/message', request);
};
const postRegisterBrowserPush = (request: any): Promise<APIData<any>> => {
  return api.post('/coach/register_browser_push', request);
};
const postUnregisterBrowserPush = (request: any): Promise<APIData<any>> => {
  return api.post('/coach/unregister_browser_push', request);
};

const getTeam = (): Promise<APIData<CoachTeam | undefined>> => {
  return api.get('/coach/team');
};
const updateTeam = (title: string, imageUrl?: string): Promise<void> => {
  return api.put('/coach/team', { title, image_url: imageUrl });
};
const startTeam = (title: string, imageUrl?: string): Promise<void> => {
  return api.post('/coach/team', { title, image_url: imageUrl });
};
const deleteTeam = (): Promise<void> => {
  return api.delete('/coach/team');
};

const postGetMessagesInConversation = (
  limit: number,
  conversationId: string,
  lessThanIndex?: string,
): Promise<APIData<PostGetMessagesResponse>> => {
  const request: PostGetMessagesRequest = {
    limit,
    conversation_id: conversationId,
    less_than_index: lessThanIndex,
  };
  return api.post(`/messages`, request);
};
const postSetConversationLastRead = (
  conversationId: string,
  lastReadMessageId: string,
): Promise<APIData<PostGetMessagesResponse>> => {
  const request: PostSetConversationReadAtRequest = {
    conversation_id: conversationId,
    last_read_message_id: lastReadMessageId,
  };
  return api.post(`/conversations/last_read_message_id`, request);
};
const deleteMessage = (messageId: string): Promise<void> => {
  return api.delete(`/messages/${messageId}`);
};
const postGetDeletedMessagesInConversation = (
  conversationId: string,
  lastCheckedAtTime?: string,
): Promise<APIData<{ deleted_message_ids: string[]; checked_at_time: string }>> => {
  return api.post(`/deleted_messages`, {
    messages_deleted_since_time: lastCheckedAtTime,
    conversation_id: conversationId,
  });
};

const postCreateConversation = (
  coachId: string,
  userId: string,
): Promise<APIData<PostCreateConversationResponse>> =>
  api.post('/coach/create_conversation', { conversation_user_ids: [coachId, userId] });

const deleteAccount = (): Promise<void> => api.delete(`/coach/account`);

const updateCustomExerciseTemplate = (templateId: string, exercise: UpdateCustomExerciseRequest) =>
  api.put(`/custom_exercise_template/${templateId}`, { exercise });

const postFeedback = (feedback: Feedback) => api.post('/v2/feedback', { feedback });

const getWorkout = (workoutId: string): Promise<APIData<Workout | PublicWorkout>> =>
  api.get(`workout/${workoutId}`);

type PlanUpdateType = 'pause' | 'cancel' | 'change' | 'resume';
const postChangePlan = (
  planUpdateType: PlanUpdateType,
  newPlanId?: string,
): Promise<APIData<{} | ErrorResponse>> =>
  api.post(`coach/change_plan`, { newPlanId, planUpdateType });

const startFreeTrial = (): Promise<APIData<{} | ErrorResponse>> =>
  api.post(`/coach/start_free_trial`);

const postSurveyResults = (surveyAnswers: any): Promise<APIData<{} | ErrorResponse>> =>
  api.post(`/coach/survey_answers`, { surveyAnswers });

const getWorkoutComments = (workoutId: string): Promise<APIData<WorkoutComment[]>> =>
  api.get(`workout_comments/${workoutId}`);
const postWorkoutComment = (workoutId: string, comment: string) =>
  api.post('workout_comment', { workoutId, comment });
const deleteWorkoutComment = (commentId: number) => api.delete(`workout_comment/${commentId}`);

const likeWorkout = (workoutId: string) => api.post(`workout/like/${workoutId}`);
const unlikeWorkout = (workoutId: string) => api.post(`workout/unlike/${workoutId}`);

const getClientExerciseSets = (
  clientId: string,
  exerciseTemplateId: string,
  since: string,
): Promise<APIData<UserExerciseSet[]>> =>
  api.get(`/coach/client_exercise_sets/${clientId}/${exerciseTemplateId}/${since}`);

const getClientsRecentExercises = (
  clientId: string,
): Promise<APIData<ClientRecentExerciseData[]>> =>
  api.get(`/coach/clients_exercises_by_date_used/${clientId}`);

const getClientBodyMeasurements = (clientId: string): Promise<APIData<BodyMeasurement[]>> =>
  api.get(`/coach/client_body_measurements/${clientId}`);
const postClientsBodyMeasurements = (measurements: PostBodyMeasurementRequest, clientId: string) =>
  api.post(`/coach/body_measurements/${clientId}`, { measurements });

const getProgramFolders = (): Promise<APIData<ProgramFolder[]>> =>
  api.get(`/coach/program_folders`);
const createProgramFolder = (title: string): Promise<APIData<{ folder_id: number }>> =>
  api.post(`/coach/program_folder`, { title });
const updateProgramFolder = (title: string, folderId: number) =>
  api.put(`/coach/program_folder`, { title, folderId });
const deleteProgramFolder = (folderId: number) => api.delete(`/coach/program_folder/${folderId}`);

const updateProgramLocations = (updates: ProgramLocationUpdate[]) =>
  api.put(`/coach/program_locations`, { updates });
const updateProgramFolderLocations = (updates: ProgramFolderLocationUpdate[]) =>
  api.put(`/coach/program_folder_order`, { reorders: updates });

const getClientWorkoutsBatch = ({
  clientId,
  startDate,
  endDate,
  index,
}: {
  clientId: string;
  startDate: string;
  endDate: string;
  index?: number;
}): Promise<APIData<Workout[]>> =>
  api.get(`/coach/client_workouts_batch/${clientId}/${index ?? ''}`, {
    params: { startDate, endDate },
  });

interface GetUserAuthTokenProps {
  /** id of user that we want to become */
  userId: string;
}

const getUserAuthToken = ({
  userId,
}: GetUserAuthTokenProps): Promise<
  APIData<{
    auth_token: string;
    username: string;
    profile_pic: string | null;
  }>
> => api.get(`user_auth_token/${userId}`);

const validateAuthToken = (authToken: string): Promise<APIData<{ auth_token: string }>> =>
  api.post('validate_auth_token', { authToken, userMustBeCoach: true });

const postAuthenticatedBootstrapComplete = (): Promise<APIData<void>> =>
  api.post('coach/bootstrap_complete');

const API = {
  deleteAccount,
  addResponseInterceptor,
  setAuthToken,
  signup,
  login,
  loginWithTempToken,
  signupFromTemporaryToken,
  getMetadataFromTemporaryToken,
  signInWithGoogle,
  signInWithApple,
  generatePasswordRecoveryEmail,
  generateDownloadLinkEmail,
  generateContinueOnDesktopEmail,
  getAccount,
  updateAccount,
  getPresignedUrl,
  getPresignedExerciseVideoUrl,
  getPresignedChatFileUrl,
  updateUsername,
  uploadFileWithPresignedUrl,
  updateEmail,
  getUserPreferences,
  updateUserPreferences,
  getClients,
  getClientsWorkoutHistory,
  getClientExerciseHistory,
  getClientsWorkoutsPaged,
  getCoachInfoForInvite,
  createClientInvite,
  createOrgInvite,
  deleteOrgInvite,
  getCustomExerciseTemplates,
  postCustomExerciseTemplate,
  deleteCustomExerciseTemplate,
  updateCustomExerciseTemplate,
  postCoachRoutine,
  createClientRoutine,
  updateCoachRoutine,
  deleteClientRoutine,
  deleteCoachRoutine,
  postProgram,
  saveProgramAsTemplate,
  duplicateProgram,
  getPrograms,
  getProgram,
  deleteProgram,
  updateProgram,
  postAddRoutineToProgram,
  assignProgramToClient,
  updateClientRoutine,
  updateCoachRoutineLocations,
  updateClientsRoutineLocations,
  postRoutineCopy: postCoachRoutineCopy,
  postFeedback,
  postWorkoutComment,
  deleteWorkoutComment,
  getWorkoutComments,
  likeWorkout,
  unlikeWorkout,
  getWorkout,
  getClientExerciseSets,
  getClientsRecentExercises,
  getClientWorkout,
  postUpdateClientNotes,
  postUpdateJoinForm,
  getCoachJoinFormMetadata,
  postCreateCoachLead,
  getCoachLeads,
  acceptCoachLead,
  deleteCoachLead,
  searchUserByEmail,
  searchUserByName,
  getClientBodyMeasurements,
  postClientsBodyMeasurements,
  deleteClient,
  getClientsActivitiesPaged,
  getWeeklyActiveClients,
  getClientWorkoutsBatch,
  getInvites,
  getOrgInvites,
  postCoachRole,
  postRemoveCoachFromTeam,
  postReassignClients,
  deleteClientInviteByShortId,
  createSampleClient,
  postChangePlan,
  startFreeTrial,
  getPlan,
  getAvailablePlans,
  getPaymentHistory,
  postExerciseCustomization,
  deleteExerciseCustomizations,
  getExerciseCustomizations,
  postSurveyResults,
  getUserAuthToken,
  validateAuthToken,
  getSimplifiedLibraryRoutines,
  getProgramRoutines,
  getRoutine,
  getHevyRoutinesSync,
  getHevyRoutineFolders,
  getProgramFolders,
  createProgramFolder,
  updateProgramFolder,
  deleteProgramFolder,
  updateProgramLocations,
  updateProgramFolderLocations,
  getConversations,
  postGetMessagesInConversation,
  postCreateConversation,
  getLatestMessageFromClientConversations,
  createMessage,
  postRegisterBrowserPush,
  postUnregisterBrowserPush,
  updateClientConfiguration,
  getConfig,
  setConfig,
  postSetConversationLastRead,
  deleteMessage,
  postGetDeletedMessagesInConversation,
  postAuthenticatedBootstrapComplete,
  getTeam,
  updateTeam,
  startTeam,
  deleteTeam,
  updateUserKeyValues,
  getUserKeyValues,
  deleteUserKeyValue,
};

export default API;
