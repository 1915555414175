import React, { useState } from 'react';
import { FAQCard } from './FAQCard';
import styled from 'styled-components';
import { DisplaySM } from 'styleguide/Texts';
import { View } from 'styleguide/View';

const FAQList = styled.div`
  width: 100%;
  height: 560px;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const renderFAQs = () => {
  const faqs: {
    title: string;
    text: string;
  }[] = [
    {
      title: 'Can I try Hevy Coach for free?',
      text: `Yes, we have a 30 day free trial for all plans, with no credit card required. You will have complete access to Hevy Coach, and at the end of your trial you'll be able to decide which plan is right for you.`,
    },
    {
      title: 'Can I cancel my subscription anytime?',
      text: 'Yes! The subscription is monthly, so you can cancel it anytime.',
    },
    {
      title: 'Do my clients need to pay anything?',
      text: 'No, your clients will not pay anything. We charge coaches a subscription to use the platform, and their clients will get coached through Hevy completely for free.',
    },
    {
      title: 'How often will I be billed?',
      text: "We bill our customers every month for their subscriptions. If you don't cancel your subscription, you'll be automatically billed each month until you decide to end your subscription. You can contact us at hello@hevycoach.com if you have any further questions.",
    },
    {
      title: 'What payment methods do you accept?',
      text: 'We accept most cards like Visa or Mastercard. Payments are processed by Paddle.com, a world class payment processor.',
    },
    {
      title: 'Is it possible to get a refund?',
      text: "Yes, if you're not happy with Hevy Pro contact support within 14 days of your purchase to qualify for a refund. Contact hello@hevycoach.com for any help.",
    },
  ];

  const [openedFaqIndex, setOpenedFaqIndex] = useState<number | undefined>(undefined);

  return faqs.map((f, index) => (
    <FAQCard
      key={`faq-${index}`}
      title={f.title}
      text={f.text}
      isFirst={index === 0}
      isOpened={index === openedFaqIndex}
      onPress={() => {
        if (index === openedFaqIndex) {
          setOpenedFaqIndex(undefined);
        } else {
          setOpenedFaqIndex(index);
        }
      }}
    />
  ));
};

export const FAQs = () => {
  return (
    <View>
      <DisplaySM style={{ flex: 1, textAlign: 'center', marginTop: 96, marginBottom: 48 }}>
        Any questions?
      </DisplaySM>
      <FAQList>{renderFAQs()}</FAQList>
    </View>
  );
};
