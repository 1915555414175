import { ExerciseTemplate, MuscleGroup, UserExerciseSet, Workout } from 'hevy-shared';
import { makeAutoObservable } from 'mobx';
import { equipmentToHuman, exerciseTypeToHuman, muscleGroupToHuman } from 'utils/exerciseUtils';
import { ExerciseHeaderProps, Media } from 'components/ExerciseHeader/types';
import { rawInstructionsToIndexedSteps } from 'screens/ExerciseLibrary/utils';
import { modal } from '../ModalManager';
import { sendEvent } from 'utils/analyticsEvents';
import { localStorageStores } from 'state/localStorageStores';
import { VideoAttachment } from 'state/localStorageStores/exerciseTemplateCustomizations';
import { Client } from 'types/client';
import { TabDescriptor } from 'components/Tabs';
import toast from 'react-hot-toast';
import dayjs from 'dayjs';
import { LineGraphProps } from 'screens/ClientDetail/Tabs/Exercise/components/LineGraph';
import { CoachUnitsAggregator } from 'state/aggregators/coachUnitsAggregator';
import API from 'utils/API';
import { getLineGraphProps } from 'screens/ClientDetail/Tabs/Exercise/utils';
import { ExerciseTemplateType } from 'types/exerciseTemplateType';

export type ExerciseDetailViewModelTabIdentifier =
  | 'exercise-details'
  | 'client-statistics'
  | 'client-history';

const isExerciseDetailViewModelTabIdentifier = (
  value: string,
): value is ExerciseDetailViewModelTabIdentifier => {
  return ['exercise-details', 'client-statistics', 'client-history'].includes(value);
};

export class ExerciseDetailViewModel {
  exerciseTemplate: ExerciseTemplate;
  exerciseTemplateType: ExerciseTemplateType;
  childCustomExerciseTemplateId?: string;
  client?: Client;

  // If the user decides to edit this exercise, this
  // callback is passed along to the edit modal
  onExerciseUpdated?: () => void;

  constructor(
    exerciseTemplate: ExerciseTemplate,
    exerciseTemplateType: ExerciseTemplateType,
    onExerciseUpdated?: () => void,
    client?: Client,
  ) {
    makeAutoObservable(this);
    this.exerciseTemplate = exerciseTemplate;
    this.exerciseTemplateType = exerciseTemplateType;

    // If the exercise is coach's custom exercise
    // We need to find the child custom exercise id for the client
    this.childCustomExerciseTemplateId = client?.customExercises.find(
      e => e.parent_exercise_template_id === exerciseTemplate.id,
    )?.id;

    this.onExerciseUpdated = onExerciseUpdated;
    this.client = client;
    this.fetchClientExerciseHistory();
    this.fetchClientExerciseSets();
  }

  selectedTab: ExerciseDetailViewModelTabIdentifier = 'exercise-details';
  get tabs(): TabDescriptor[] {
    if (!this.client) {
      return [];
    }

    return [
      { id: 'exercise-details', title: 'Exercise Details' },
      { id: 'client-statistics', title: 'Client Statistics' },
      { id: 'client-history', title: 'Client History' },
    ];
  }

  onTabSelected = (tabIdentifier: string) => {
    if (isExerciseDetailViewModelTabIdentifier(tabIdentifier)) this.selectedTab = tabIdentifier;
  };

  get header(): ExerciseHeaderProps & {
    exerciseTemplateType: ExerciseTemplateType;
  } {
    const media = ((): Media | undefined => {
      if (this.exerciseTemplate.custom_exercise_image_url) {
        return { type: 'image', url: this.exerciseTemplate.custom_exercise_image_url };
      }

      switch (this.exerciseTemplate.media_type) {
        case 'video':
          return { type: 'video', url: this.exerciseTemplate.url || '' };
        case 'image':
          return { type: 'image', url: this.exerciseTemplate.url || '' };
        default:
          return undefined;
      }
    })();

    const equipment = this.exerciseTemplate.equipment_category || 'barbell';
    const muscleGroup = this.exerciseTemplate.muscle_group || 'other';
    const otherMuscles: MuscleGroup[] = this.exerciseTemplate.other_muscles || [];
    const exerciseType = this.exerciseTemplate.exercise_type || 'weight_reps';

    return {
      title: this.exerciseTemplate.title,
      media,
      infos: [
        {
          key: `Equipment: `,
          value: equipmentToHuman(equipment),
        },
        { key: 'Primary Muscle Group: ', value: muscleGroupToHuman(muscleGroup) },
        ...(!!otherMuscles.length
          ? [
              {
                key: 'Secondary Muscle Groups: ',
                value: `${otherMuscles.map(mg => muscleGroupToHuman(mg)).join(', ')}`,
              },
            ]
          : []),
        {
          key: 'Exercise Type: ',
          value: exerciseTypeToHuman(exerciseType),
        },
      ],
      isCustom: this.exerciseTemplate.is_custom,
      exerciseTemplateType: this.exerciseTemplateType,
    };
  }

  get exerciseInstructions() {
    return rawInstructionsToIndexedSteps(this.exerciseTemplate.instructions ?? '');
  }

  get customExerciseInstructions() {
    if (!this.exerciseTemplate) {
      return;
    }

    return localStorageStores.exerciseTemplateCustomizations.getExerciseCustomization(
      this.exerciseTemplate.id,
    )?.instructions;
  }

  get videoAttachment(): VideoAttachment | undefined {
    if (!this.exerciseTemplate) {
      return;
    }

    const customization =
      localStorageStores.exerciseTemplateCustomizations.getExerciseCustomization(
        this.exerciseTemplate.id,
      );

    if (customization?.video) {
      return {
        type: customization.video.type,
        url: customization.video.url,
      };
    }
  }

  onPressedEdit = () => {
    sendEvent('exerciseDetailModal_editExerciseClick', {
      exerciseId: this.exerciseTemplate?.id,
    });

    modal.openEditExerciseModal({
      onExerciseSaved: () => {
        sendEvent('exerciseDetailModal_editExercise_Complete');
        this.onExerciseUpdated?.();
      },
      exerciseId: this.exerciseTemplate?.id,
    });
    modal.isExerciseDetailModalOpen = false;
  };

  exerciseWorkoutHistoryLessThanIndex?: number;
  workoutsWithExercise: Workout[] = [];

  get exerciseWorkoutHistory(): Workout[] {
    return this.workoutsWithExercise.map(w => {
      return {
        ...w,
        exercises: w.exercises.filter(
          e =>
            e.exercise_template_id === this.exerciseTemplate.id ||
            e.exercise_template_id === this.childCustomExerciseTemplateId,
        ),
      };
    });
  }

  isLoadingWorkoutHistory: boolean = false;
  canLoadMoreWorkoutHistory = true;
  fetchClientExerciseHistory = async () => {
    if (
      this.isLoadingWorkoutHistory ||
      this.canLoadMoreWorkoutHistory === false ||
      !this.client?.id
    )
      return;
    this.isLoadingWorkoutHistory = true;
    try {
      const response = (
        await API.getClientExerciseHistory(
          this.client.id,
          this.childCustomExerciseTemplateId ?? this.exerciseTemplate.id,
          this.exerciseWorkoutHistoryLessThanIndex,
        )
      ).data;
      if (response.length > 0) {
        this.canLoadMoreWorkoutHistory = true;
        this.workoutsWithExercise.push(...response);
        this.exerciseWorkoutHistoryLessThanIndex =
          this.workoutsWithExercise[this.workoutsWithExercise.length - 1]?.index;
      } else {
        this.canLoadMoreWorkoutHistory = false;
      }
    } catch (error) {
      this.canLoadMoreWorkoutHistory = true;
      toast.error('Unable to load client exercise history.');
    } finally {
      this.isLoadingWorkoutHistory = false;
    }
  };

  isFetchingExerciseSets: boolean = false;
  private setData: UserExerciseSet[] = [];
  fetchClientExerciseSets = async () => {
    if (!this.client) {
      return;
    }

    this.isFetchingExerciseSets = true;
    try {
      const response = await API.getClientExerciseSets(
        this.client.id,
        this.childCustomExerciseTemplateId ?? this.exerciseTemplate.id,
        dayjs('2019-04-11T11:13:51.984Z').format(),
      );
      this.setData = response.data;
    } catch {
      toast.error('Failed to load client exercise data');
    } finally {
      this.isFetchingExerciseSets = false;
    }
  };

  get chartData(): LineGraphProps[] {
    const { weightUnit, distanceUnit, distanceUnitShort } = CoachUnitsAggregator.preferences;
    return getLineGraphProps(
      this.exerciseTemplate.exercise_type,
      weightUnit,
      distanceUnit,
      distanceUnitShort,
      this.setData,
    );
  }
}
