var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"0e5ddb8f7edb16726e01fc29c9deb8ffff705d74"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import axios from 'axios';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
  ignoreErrors: [
    'Network Error',
    'timeout of 20000ms exceeded',
    /**
     * https://hevy.sentry.io/issues/3969394684/?project=4504726557425664&query=&referrer=issue-stream&sort=user&statsPeriod=14d&stream_index=1
     * Fetch failure on Apple devices
     */
    'Load failed',
    'TypeError: Failed to fetch',
    'TypeError: NetworkError when attempting to fetch resource.',
  ],
  dsn:
    SENTRY_DSN ||
    'https://64a51f9fb1b6488f827e7d30fa6bd99a@o276807.ingest.sentry.io/4504726557425664',
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 1.0,

  enabled: process.env.NEXT_PUBLIC_ENV === 'production',
  attachStacktrace: true,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0.1,
  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 1.0,

  integrations: [new Sentry.Replay()],
  beforeSend: event => {
    /**
     * https://hevy.sentry.io/issues/4620668251/events/2c35954748cd4c0c8cf8b03798bb94fe/?project=4504726557425664&query=&referrer=previous-event&sort=user&statsPeriod=14d&stream_index=0
     * Seems to be a non-user-impacting drag and drop error
     */
    if (event.message === 'Invariant failed') {
      return null;
    }
    // Filter out errors we don't want to send to discord
    const possibleErrorDescription = event.exception?.values?.[0]?.value;
    if (
      possibleErrorDescription
        ?.toLowerCase()
        .includes('abort route change. please ignore this error.')
    ) {
      return null;
    }

    if (event.message?.includes('Failed to fetch')) {
      // Network failure, typically reported on Apple browsers
      return null;
    }

    if (
      possibleErrorDescription
        ?.toLowerCase()
        .includes('Request failed with status code 403'.toLowerCase()) ||
      possibleErrorDescription
        ?.toLowerCase()
        .includes('Request failed with status code 401'.toLowerCase())
    ) {
      // This seems to happen specifically when the user's trial/plan expires, but isn't really an issue.
      // https://hevy.sentry.io/issues/4114296896
      return null;
    }
    if (axios.isAxiosError(event)) {
      return new Error(`Axios network error: ${error.config.baseURL}/${error.config.url}`, {
        cause: JSON.stringify(error),
      });
    }
    return event;
  },
});
